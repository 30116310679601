.inputBlock {
    width: 90%;
    justify-content: flex-start;
    align-items: start;
    text-align: left;
    display: grid;
    grid-template-columns: 1fr;
}

.formBlock {
    width: 90%;
    justify-content: flex-start;
    align-items: start;
    text-align: left;
    display: grid;
    grid-template-columns: 1fr;
}

.editbox {
    border: 1px solid rgb(155, 155, 155);
    border-radius: 5px;   
}

.editbutton {
    width: 20px;
    height: 20px;
    text-align: center;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;   
    cursor: pointer;
    font-weight: bold;
}