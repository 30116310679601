 
.zodr {
    background-color: cadetblue;
    color: black;
}

.mist {
    background-color: #ffcc00;
    color: black;
}

.prg_wmn {
    background-color: #ff6666;
    color: black;
}

.fist {
    background-color: #ff9933;
    color: black;
}   

.lead {
    background-color: #ff3399;
    color: black;
}
.dsh {
    background-color: #ff99ff;
    color: black;
}   

.G{
    background-color: #02ff24;
    color: black;
}

.bold {
    font-weight: bold;
}

.alt {
    background-color: #d1d1d1;
    color: black;
}

.white {
    background-color: white;
    color: black;
}