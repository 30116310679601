 
.columns2 {
    display : grid;
    grid-template-columns: 1fr 1fr;
}

.columns2firstSmall{
    display : grid;
    grid-template-columns: 25rem 1fr;
}

.columns3 {
    display : grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.columns3lastLarge {
    display : grid;
    grid-template-columns: 1fr 1fr 2fr;
}

.columns4lastLarge {
    display : grid;
    grid-template-columns: 10rem 10rem 10rem 1fr;
}

.columns4 {
    display : grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;    
}

.columns5 {
    display : grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.columns5firstLarge {
    display : grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
}

.columns6 {
    display : grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.columns10 {
    display : grid;
    grid-template-columns: repeat(10,1fr);
}

.columns11 {
    display : grid;
    grid-template-columns: repeat(11,1fr);
}

.columns {
    display : grid;
    grid-template-columns: auto;
}

.centered { 
    justify-items: center;
}


.threeRows{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 10px;
    margin: 10px;
    padding: 10px;
    border: 1px solid black;
    border-radius: 5px;
    background-color: #f2f2f2;
    box-shadow: 5px 5px 5px #888888;
} 

.sixRows {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(1fr, min(2fr,4fr));
    grid-gap: 10px;
    margin-bottom: 1rem;
    
}

.boxed {
    border: 1px solid black;
    border-radius: 5px;
    background-color: #f2f2f2;
    box-shadow: 5px 5px 5px #888888;
    margin: 10px;
    padding: 10px;
    padding-bottom: 1rem;
}


.almostboxed {        
    margin: 10px;
    padding: 10px;
    padding-bottom: 1rem;
}

.spaced {
    margin-right: 2px;
    
    
}
