
.programSquare {    
    display: grid;
    margin-Right :0.5rem;
    border-radius: 5px;    
    border: 1px solid #000000;
    
    
    text-align: center;
    font-size: 14px;
    font-weight: bold;
 
}

.programSquareContainer {
    display: grid;
    margin-bottom: 2px;
    grid-template-columns: 3rem 1fr;
}

.programSquare a{
    text-decoration: none;
    color: black;
}

.header {
    display: grid;
    grid-template-columns: 3fr 1fr 2fr;
}

.headerBody {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}


