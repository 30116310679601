
.modalBanner {
    display: grid;
    grid-template-columns: 1fr 30px;
    
}

.modalClose {
    height: 20px;
} 
