 
.withsidebar {
    display: grid;
    grid-template-columns: 26rem 1fr;
    height: 80vh; /* Set the height to 80% of the viewport height */
    overflow-y: auto; /* Enable vertical scrolling for the children */
}


.button-link {
    display: inline-block;
    padding: 0.5em 1em;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 0.25em;
    border: none;
    box-shadow: none;
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
  }
  
  .padding1rem { 
    padding-left: 2rem;
    
}