.inputBlock {
    width: 90%;
    justify-content: flex-start;
    align-items: start;
    text-align: left;
    display: grid;
    grid-template-columns: 1fr;
}

.formBlock {
    width: 90%;
    justify-content: flex-start;
    align-items: start;
    text-align: left;
    display: grid;
    grid-template-columns: 1fr;
}