input, textarea, input[type="radio"] { 
    margin-top: 0.2rem;
}

textarea{
    padding: 5px 10px;
    min-width: 50%;
    min-height: 10rem;
}

input[type="password"] {
    padding: 5px 10px;
    min-width: 20rem;
}


input[type="text"] {
    padding: 5px 10px;
    min-width: 20rem;
}

textarea, input[type="text"], input[type="password"] {
    border-radius: 2px;
    border: 1px solid #ced4da;
} 


input[type="radio"] {
    border-radius: 2px;
    border: 1px solid #ced4da;
}

input[type="date"] {
    border-radius: 2px;
    border: 1px solid #ced4da;
    
}

.label {
    padding-right: 3rem;
}

.inputBlock {    
    margin-bottom: 1.5rem;
}

.formBlock {
    margin: 10px;
    padding: 10px;
    background-color: darkgray;
}
 

.button {
    height: 1.5rem;
}

.rowsSpaced {
    grid-row-gap: 1.5rem;
}

.grey-background {
    background-color: #f8f9fa;
}