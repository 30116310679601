body {
    font-family: 'Montserrat', sans-serif;
    overflow: auto;
}


input, textarea, input[type="radio"] { 
    margin-top: 0.5rem;
}

textarea{
    padding: 5px 10px;
    min-width: 50%;
    min-height: 10rem;
}


input[type="text"] {
    padding: 5px 10px;
    min-width: 20rem;
}

textarea, input[type="text"] {
    border-radius: 5px;
    border: 1px solid #afabab;
} 


input[type="radio"] {
    margin-right: 0.25rem;
}

.label {
    padding-right: 3rem;
}

.inputBlock {    
    margin-bottom: 1.5rem;
}

.section {
    margin: 10px;
    padding: 10px;
    border: 1px solid black;
    border-radius: 5px;
    background-color: #f2f2f2;
    box-shadow: 5px 5px 5px #888888;
}

.link {
    color: #0000EE;
    text-decoration: underline;
    cursor: pointer;
}