 
body {
    font-family: 'Montserrat', sans-serif;
    overflow: auto;
    background: #ccc;
    color: #000;
}



.parent {
    margin: 10px;
    padding: 10px;
    border: 1px solid black;
    border-radius: 5px;
    background-color: #f2f2f2;
    box-shadow: 5px 5px 5px #888888;
}

.warning {
    background-color: #ff6666;
    color: black;
}



